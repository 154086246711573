import {Injectable} from '@angular/core'
import {
  Applicant,
  Car,
  Child,
  Income,
  Kalpylator,
  LivingExpenseDeduction,
  Loan,
  Parameters,
  Property
} from '@sparbanken-syd/kalpylator'
import {PropertyType} from '@sparbanken-syd/kalpylator/types/public'
import {BehaviorSubject, merge, Observable, Subject} from 'rxjs'

export const MONTHLY3_TEMPLATE_INTEREST = 6
export type TDomains = 'borgo' | 'sparbanken'

@Injectable({
  providedIn: 'root'
})
export class KalpService {

  /**
   * We store the last step here, if the user
   * was in step 6 when asking for a KALP we can
   * remember that for them. Basically just reusing this
   * service for whatever.
   */
  public lastStep = 0

  /**
   * Below subjects are for sending data to us.
   */
  public applicants$: BehaviorSubject<Applicant[]> = new BehaviorSubject<Applicant[]>([])

  public children$: BehaviorSubject<Child[]> = new BehaviorSubject<Child[]>([])

  public incomes$: BehaviorSubject<Income[]> = new BehaviorSubject<Income[]>([])

  public loans$: BehaviorSubject<Loan[]> = new BehaviorSubject<Loan[]>([])

  public properties$: BehaviorSubject<Property[]> = new BehaviorSubject<Property[]>([])

  public cars$: BehaviorSubject<Car[]> = new BehaviorSubject<Car[]>([])

  public changes$: Observable<any>

  public applicantCount$: Observable<number>

  public calculationsPossible$: Observable<boolean>

  /**
   * Help innocent bystanders know if we have calculated
   * something. Loads of rows for no real value?
   */
  private pCalculationsPossible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  private applicantCount: BehaviorSubject<number> = new BehaviorSubject<number>(1)

  /**
   * Variables and parameters
   */

  /**
   * Base monthly expenses for different household types
   */
  private ADULT_EXPENSE = 9600
  private TWO_ADULTS_EXPENSE = 8500

  private RETIRED_EXPENSE = 8200
  private TWO_RETIRED_EXPENSE = 7200

  /**
   * Household deduction calculations
   * Compares total individual expenses vs shared household expenses
   */
  private TWO_EMPLOYED_DEDUCTION = (2 * this.ADULT_EXPENSE) - (2 * this.TWO_ADULTS_EXPENSE)
  private MIXED_HOUSEHOLD_DEDUCTION = (this.ADULT_EXPENSE + this.RETIRED_EXPENSE) - (this.TWO_ADULTS_EXPENSE + this.TWO_RETIRED_EXPENSE)
  private TWO_RETIRED_DEDUCTION = (2 * this.RETIRED_EXPENSE) - (2 * this.TWO_RETIRED_EXPENSE)

  private LIVING_EXPENSE_DEDUCTION: LivingExpenseDeduction[] = [
    {class: ['EMPLOYED', 'EMPLOYED'], deduction: this.TWO_EMPLOYED_DEDUCTION},
    {class: ['EMPLOYED', 'RETIRED'], deduction: this.MIXED_HOUSEHOLD_DEDUCTION},
    {class: ['RETIRED', 'RETIRED'], deduction: this.TWO_RETIRED_DEDUCTION}
  ]

  private YEARLY_TAX_VALUE = 9525
  private ALMOST_RETIRED_INCOME_FACTOR = 0.7
  private CHILDREN_BENEFIT = 1250
  private CHILDREN_BENEFIT_EXTRA: [0, number, number, number, number, number, number] = [0, 0, 150, 730, 1740, 2990, 4240]
  private LIVING_EXPENSES = [
    {age: 6, expense: 3800, childrenBenefit: this.CHILDREN_BENEFIT},
    {age: 13, expense: 4300, childrenBenefit: this.CHILDREN_BENEFIT},
    {age: 15, expense: 5500, childrenBenefit: this.CHILDREN_BENEFIT},
    {age: 18, expense: 5500, childrenBenefit: 1041},
    {age: 64, expense: this.ADULT_EXPENSE},
    {age: 67, expense: this.RETIRED_EXPENSE},
    {age: 200, expense: this.RETIRED_EXPENSE}
  ]
  private LOAN_DEFAULTS = {
    MORTGAGE: {
      interest: MONTHLY3_TEMPLATE_INTEREST / 100,
      mortgagePercent: 0.01
    },
    BLANCO: {
      interest: 0.1,
      mortgagePercent: 0.1
    },
    CREDIT: {
      interest: 0.1,
      mortgagePercent: 0
    },
    BORGEN: {
      interest: 0.1,
      mortgagePercent: 0.1
    }
  }
  private PROPERTY_COST_DEFAULTS = {
    VILLA: {
      runCost: 4800,
      yearlyTax: this.YEARLY_TAX_VALUE
    },
    HYRES: {
      runCost: 500,
      yearlyTax: 0
    },
    BOSTADSRATT: {
      runCost: 900,
      yearlyTax: 0
    },
    FRITIDSHUS: {
      runCost: 2100,
      yearlyTax: this.YEARLY_TAX_VALUE
    }
  }
  private CAR_DEFAULTS = {
    LEASE: {
      cost: 4700
    },
    OWN: {
      cost: 2500
    }
  }

  private borgo: Parameters = {
    domain: 'borgo',
    defaultTaxTable: 33,
    livingExpenseDeduction: this.LIVING_EXPENSE_DEDUCTION,
    livingExpenses: this.LIVING_EXPENSES,
    cityDwellerFactor: 1.05,
    almostRetiredThreshold: 60,
    almostRetiredLivingExpense: this.ADULT_EXPENSE,
    almostRetiredIncomeFactor: this.ALMOST_RETIRED_INCOME_FACTOR,
    childrenBenefit: this.CHILDREN_BENEFIT,
    childrenBenefitExtra: this.CHILDREN_BENEFIT_EXTRA,
    loanDefaults: this.LOAN_DEFAULTS,
    propertyDefaults: this.PROPERTY_COST_DEFAULTS,
    carDefaults: this.CAR_DEFAULTS
  }
  private sparbanken: Parameters = {
    defaultTaxTable: 33,
    domain: 'default',
    livingExpenseDeduction: this.LIVING_EXPENSE_DEDUCTION,
    livingExpenses: this.LIVING_EXPENSES,
    cityDwellerFactor: 1,
    almostRetiredThreshold: 62,
    almostRetiredLivingExpense: this.ADULT_EXPENSE,
    almostRetiredIncomeFactor: this.ALMOST_RETIRED_INCOME_FACTOR,
    childrenBenefit: this.CHILDREN_BENEFIT,
    childrenBenefitExtra: this.CHILDREN_BENEFIT_EXTRA,
    loanDefaults: this.LOAN_DEFAULTS,
    propertyDefaults: this.PROPERTY_COST_DEFAULTS,
    carDefaults: this.CAR_DEFAULTS
  }

  private readonly propertyMap: Record<TDomains, Parameters>

  private readonly inputs: Subject<any>[]

  constructor() {
    this.calculationsPossible$ = this.pCalculationsPossible$.asObservable()

    this.propertyMap = {
      borgo: this.borgo,
      sparbanken: this.sparbanken
    }

    // All our inputs if someone cares.
    this.inputs = [this.applicants$, this.children$, this.incomes$, this.loans$, this.properties$, this.cars$]
    this.changes$ = merge(...this.inputs)

    // Get and emit number of applicants
    this.applicantCount$ = this.applicantCount.asObservable()
    this.applicants$.subscribe({
      next: (a: Applicant[]) => this.applicantCount.next(a.length)
    })
  }

  public setInterest(interest: any): void {
    this.borgo.loanDefaults['MORTGAGE'].interest = interest.month3 / 100
    this.sparbanken.loanDefaults['MORTGAGE'].interest = interest.month3 / 100
  }

  public getKalpylator(domain: TDomains): Kalpylator {
    // Yes we have enough data? This is in the wrong place, but
    // if someone asks for a Kalpylator then we are good to go.
    this.pCalculationsPossible$.next(true)
    const k = new Kalpylator(this.propertyMap[domain])

    // Make copies to avoid trashing the originals...
    k.set<Applicant>(this.applicants$.value.map((a: Applicant) => Object.assign({}, a)))
    k.set<Income>(this.incomes$.value.map((i: Income) => Object.assign({}, i)))
    k.set<Property>(this.properties$.value.map((p: Property) => Object.assign({}, p)))
    k.set<Child>(this.children$.value.map((c: Child) => Object.assign({}, c)))
    k.set<Loan>(this.loans$.value.map((l: Loan) => Object.assign({}, l)))
    k.set<Car>(this.cars$.value.map((c: Car) => Object.assign({}, c)))
    return k
  }

  /**
   * Get the default run cost for a property (as in a building)
   */
  public propertyDefaults(property: Property, domain: TDomains): Property {
    const defaults = this.propertyMap[domain]
    property.runCost = defaults.propertyDefaults[property.propertyType].runCost
    return property
  }

  public reset(): void {
    this.inputs.forEach((s: Subject<any>) => s.next([]))
    this.pCalculationsPossible$.next(false)
    this.lastStep = 0
  }

  /**
   * Added to get some direct access to run cost in help text
   */
  public getRunCost(domain: TDomains, propertyType: PropertyType = 'VILLA'): number {
    return this.propertyMap[domain].propertyDefaults[propertyType].runCost
  }

  /**
   * Added to get the tax in help texts
   */
  public getYearlyTax(domain: TDomains, propertyType: PropertyType = 'VILLA'): number {
    return this.propertyMap[domain].propertyDefaults[propertyType].yearlyTax
  }
}
